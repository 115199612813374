import * as React from 'react'

import Page from '../components/Page'

export default function DisclaimersPage() {
  return (
    <Page>
      <section>
        <h1>Disclaimers</h1>
        <h2>Not investment advice</h2>
        <p>
          Kensho Technologies LLC. (“Kensho”) is not an investment adviser or broker-dealer and
          Kensho makes no representation regarding the advisability of investing in any investment
          fund, other investment vehicle, security or other financial product regardless of whether
          or not it is based on, derived from, or included as a constituent of any Kensho New
          Economy Indices (“Kensho indices” and individually, each a “Kensho index”). Kensho is not
          in the business of providing investment advice, effecting transactions in securities, or
          promulgating analysis or reports on the value of securities, or the advisability of
          investing in securities, swaps, security-based swaps, commodity interests or other
          financial products. A Kensho index and any information provided relating to a Kensho index
          is not and should not be considered or construed as investment advice. No representation
          is being made as to the suitability of any Kensho index for any purpose. Kensho is not
          acting as an investment adviser or portfolio manager. Kensho bears no responsibility or
          liability for any business decision, input, recommendation, or action taken based on
          Kensho indices. The material on the web site is for informational purposes only.
          Historical data and analysis should not be taken as an indication or guarantee of any
          future performance, analysis, forecast or prediction.
        </p>
        <p>
          KENSHO MAKES NO REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED, AND HEREBY EXPRESSLY
          DISCLAIMS ANY AND ALL WARRANTIES WITH RESPECT TO ANY KENSHO INDICES, INCLUDING WARRANTIES
          OF MERCHANTABILITY, ACCURACY, COMPLETENESS, AND FITNESS FOR ANY PARTICULAR PURPOSE.
        </p>
      </section>
      <section>
        <h2>Limitation of liability</h2>
        <p>
          Any financial products discussed, mentioned or advertised on any web pages (whether on
          Kensho’s pages or any other sites or links), whether or not they appear near or on the
          same page as a Kensho index, are not sponsored, endorsed, sold, or promoted by Kensho
          unless otherwise stated. Kensho does not make any representation or warranty, express or
          implied, to the holders of any financial products or any member of the public regarding
          the advisability of investing in any particular financial product. Kensho shall have no
          liability whatsoever and expressly disclaims any liability to anyone from investing in any
          financial product (whether or not it is based on or related to a Kensho index), and
          disclaims any liability for anyone’s use of a financial product or Kensho index that
          results in any losses, damages, costs, legal fees, charges, expenses or other liabilities
          however arising, including without limitation: liability for any special, punitive,
          indirect or consequential damages (including, without limitation, loss of business or loss
          of profit, opportunity costs, loss of time and loss of goodwill), even if notified of the
          possibility of the same, arising in connection with the design, compilation, calculation,
          maintenance or sponsoring of a Kensho index or in connection with any financial product.
          Where any applicable law does not allow the limitation of liability in the manner
          described above, Kensho’s total liability to you for all damages, loss and causes of
          action (whether in contract or in tort, including gross negligence) shall not exceed the
          amount paid by you, if any, for accessing the website.
        </p>
        <p>
          Kensho indices are determined, composed, and calculated without regard to any financial
          products, and Kensho has no obligation to take the needs of holders of any financial
          products into consideration when determining, composing, or calculating a Kensho index.
        </p>
        <p>
          While Kensho seeks to ensure the accuracy of the information it presents, it has no
          obligation and shall bear no liability for errors in any Kensho indices or financial
          products. Furthermore, Kensho does not guarantee the continuity of the composition of any
          Kensho index, the continuity of the method of calculation of any Kensho index, the
          continuity of the dissemination of the index levels of any Kensho index, or the continuity
          of the calculation of any Kensho index. Kensho’s indices are not an assurance or opinion
          with regard to investment in any financial product whatsoever. You may not modify, copy,
          distribute, transmit, display, perform, reproduce, publish, license, frame, decompile,
          recompile, reverse engineer, upload to, create derivative works from, transfer, or
          otherwise use or exploit in whole or in part any information, software, products or
          services obtained from this web site, except for the purposes expressly provided herein,
          without Kensho’s prior written approval. You agree to indemnify, defend and hold harmless
          the Kensho and its affiliates, partners, and representatives from and against any claims,
          losses, damages, liabilities, costs and expenses, including, without limitation,
          reasonable attorneys’ and experts’ fees and costs, as incurred, arising in any manner out
          of your use of, or inability to use, any index data contained on Kensho’s web sites.
        </p>
      </section>
      <section>
        <h2>Intellectual property</h2>
        <p>
          Kensho’s name, New Economy, and all Kensho index names are protected by copyright,
          trademark, service mark, and intellectual property laws in the United States and
          internationally. All right, title, and interest in this web site and all content contained
          herein is the exclusive property of Kensho, unless otherwise stated. All rights reserved.
        </p>
      </section>
      <section>
        <h2>Performance disclosure</h2>
        <p>
          Any performance information regarding a Kensho index, including performance graphs, are
          provided for illustrative purposes only. No assurances are given with respect to the
          future performance of any Kensho index and past performance is not an indication of future
          results. Any information presented prior to the index live date is hypothetical and
          back-tested. Hypothetical back-tested performance is not actual performance and is subject
          to significant limitations. Hypothetical back-tested calculations are prepared with the
          benefit of hindsight. Back-tested calculations may involve the use of proxy or substitute
          index components or index methodology adjustments where necessary. The actual future
          performance of any Kensho index may bear little relation to the historical or hypothetical
          back-tested performance of any Kensho index.
        </p>
      </section>
    </Page>
  )
}
